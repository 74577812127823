import React from "react";
import { graphql } from "gatsby";
import Link from "../../../gatsby-theme-podcast/src/components/link";

export default function Index({ data: { allEpisode, allMarkdownRemark } }) {
  return (
    <>
      <div className="page-content">
        <h1>Tutte le puntate</h1>
        <h2>Stagione 3</h2>
        {allEpisode.nodes
          .filter((e) => e.season_number === "3")
          .map((episode) => (
            <p>
              <Link to={episode.fields.slug}>{episode.title}</Link>
            </p>
          ))}
        <h2>Stagione 2</h2>
        {allEpisode.nodes
          .filter((e) => e.season_number === "2")
          .map((episode) => (
            <p>
              <Link to={episode.fields.slug}>{episode.title}</Link>
            </p>
          ))}
        <h2>Stagione 1</h2>
        {allEpisode.nodes
          .filter((e) => e.season_number === "1")
          .map((episode) => (
            <p>
              <Link to={episode.fields.slug}>{episode.title}</Link>
            </p>
          ))}
      </div>
    </>
  );
}

export const indexQuery = graphql`
  query {
    allEpisode {
      totalCount
      nodes {
        id
        title
        description
        number
        season_number
        fields {
          slug
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            id
            title
            image {
              childImageSharp {
                original {
                  src
                }
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
